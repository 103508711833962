import React from "react"

const TestimonialCard = ({ testimonialCard }) => {
  //console.log("testimonial Card: ", testimonialCard)
  return (
    <li>
      <div>
        <h3 className="uk-margin-small-top">
          {testimonialCard.content.data.content}
        </h3>
        <p style={{ color: "#FFFFFF" }}>{testimonialCard.name}</p>
      </div>
    </li>
  )
}

export default TestimonialCard
