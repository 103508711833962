import React from "react"

const Tiles = () => {
  return (
    <div className="uk-section uk-animation-slide-right-medium">
      <div className="uk-container uk-container-large">
        <div
          className="uk-child-width-1-2@s uk-grid-collapse uk-text-center uk-box-shadow-large"
          uk-grid="true"
          uk-scrollspy="target: h4; cls:uk-animation-fade; delay: 500"
        >
          <div>
            <div className="uk-tile uk-tile-default ">
              <h4 className="uk-h4">Collaboration</h4>
            </div>
          </div>
          <div>
            <div className="uk-tile uk-tile-muted">
              <h4 className="uk-h4">Ethics</h4>
            </div>
          </div>
          <div>
            <div
              className="uk-tile uk-light tileText"
              style={{ background: "#617F7F" }}
            >
              <h4 className="uk-h4">Transparency</h4>
            </div>
          </div>
          <div>
            <div
              className="uk-tile uk-light tileText"
              style={{ background: "#000000" }}
            >
              <h4 className="uk-h4">Trust</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tiles
