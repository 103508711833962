import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Headings from "./headings"

const FeaturedSponsorship = ({ sponsorshipSections, title, description, bg = "uk-background-default" }) => {
  /* console.log(
    "Featured Sponsorship: ",
    sponsorshipSections.sponsorship_sections
  ) */

  return (
    <div
      className={`uk-section ${bg} uk-padding-remove`}
      //className="uk-section uk-section-default uk-padding-remove uk-margin-small-top"
    >
        <div className="uk-container uk-margin-small-top uk-margin-medium-bottom">
        <Headings
          title={title}
          description={description}
          bg={bg}
        />
        <div
          className="uk-position-relative uk-visible-toggle uk-light"
          tabIndex="-1"
          uk-slideshow="animation: fade; max-height: 750"
        >
          <ul className="uk-slideshow-items">
            {sponsorshipSections.sponsorship_sections.map((section, index) => {
              //console.log("Sponsorship Section: ", section)
              return (
                <li key={index}>
                  {section?.image && (
                    <GatsbyImage
                      image={getImage(section.image.localFile)}
                      alt={section.image.alternativeText}
                      title={section.image.alternativeText}
                    />
                  )}
                  <div className="uk-overlay uk-overlay-primary uk-position-bottom  uk-text-center uk-transition-slide-bottom">
                    <h3 className="">{section.name}</h3>
                    <p className="uk-visible@s">{section.subHeading}</p>

                    <Link
                      to={section.link}
                      toggle="button"
                      className="uk-button uk-button-default uk-button-small uk-text-small"
                      style={{ borderColor: "#FFFFFF", color: "#FFFFFF", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px", marginBottom: "20px" }}
                      
                    >
                      Read my story
                      <span
                        className="uk-margin-small-left"
                        uk-icon="icon: arrow-right; ratio: 1"
                      ></span> 
                    </Link>
                    <br />
                    {section?.banner && (
                      <GatsbyImage
                        image={getImage(
                          section.banner.localFile.childImageSharp.gatsbyImageData
                        )}
                        alt={section.banner.alternativeText}
                        title={section.banner.alternativeText}
                        style={{ maxWidth: "220px" }}
                        className="uk-margin-small-bottom"
                      />
                    )}
                  </div>
                </li>
              )
            })}
          </ul>

          <div className="uk-position-bottom-center uk-position-small">
            <ul className="uk-dotnav">
              <li uk-slideshow-item="0">
                <a href="/">Item 1</a>
              </li>
              <li uk-slideshow-item="1">
                <a href="/">Item 2</a>
              </li>
              <li uk-slideshow-item="2">
                <a href="/">Item 3</a>
              </li>
            </ul>
          </div>

          <a
            className="uk-position-center-left uk-position-small uk-hidden-hover"
            href="/"
            uk-slidenav-previous="true"
            uk-slideshow-item="previous"
            aria-label="Previous"
          >
            {null}
          </a>
          <a
            className="uk-position-center-right uk-position-small uk-hidden-hover"
            href="/"
            uk-slidenav-next="true"
            uk-slideshow-item="next"
            aria-label="Next"
          >
            {null}
          </a>
        </div>
      </div>
    </div>
  )
}

export default FeaturedSponsorship
